@font-face {
	font-family: 'Inter';
	src: url(./fonts/inter-latin-400-normal.ttf) format('truetype');
	font-weight: 400;
}

@font-face {
	font-family: 'Inter';
	src: url(./fonts/inter-latin-600-normal.ttf) format('truetype');
	font-weight: 600;
}

@font-face {
	font-family: 'Inter';
	src: url(./fonts/inter-latin-700-normal.ttf) format('truetype');
	font-weight: 700;
}

@font-face {
	font-family: 'Archia';
	src: url(./fonts/Archia-Regular.otf) format('opentype');
	font-weight: 400;
}

@font-face {
	font-family: 'Archia';
	src: url(./fonts/Archia-Medium.otf) format('opentype');
	font-weight: 600;
}

@font-face {
	font-family: 'Archia';
	src: url(./fonts/Archia-SemiBold.otf) format('opentype');
	font-weight: 700;
}

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

body {
	margin: 0;
	font-family: Inter, system-ui, Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	line-height: 1.5;
	height: 100vh;
	width: 100vw;
	overflow: hidden;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
